
import { Component, Vue } from "vue-property-decorator";
import Config from "@/constants/Config";
import TokenStorage from "@/auth/services/TokenStorage";
import { store } from "@/store/main";
import router from "@/router";

@Component
export default class LoginPage extends Vue {
  mounted(): void {
    if ("jwt" in this.$route.query && "refresh_jwt" in this.$route.query) {
      this.handleCallbackFromAuthClient();
    } else {
      this.openAuthClient();
    }
  }

  openAuthClient(): void {
    const url = new URL("/login", Config.AuthClientUrl);
    url.searchParams.set("app_id", Config.AuthApplicationId);
    url.searchParams.set(
      "redirect_url",
      window.location.protocol + "//" + window.location.host
    );
    url.searchParams.set("locale", "en");
    window.open(url.toString(), "_self");
  }

  handleCallbackFromAuthClient(): void {
    const { jwt, refresh_jwt: refreshJwt } = this.$route.query as Record<
      string,
      string
    >;
    TokenStorage.storeToken(jwt);
    TokenStorage.storeRefreshToken(refreshJwt);
    store.commit("auth/setAuthState");
    router.push({ name: "Company" });
    store.dispatch("makeXDCRequest", store.state.activities);
  }
}
