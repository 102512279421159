import Activity from "@/models/activity/Activity";
import ScopeEmissions from "@/models/activity/ScopeEmissions";
import ISOCountry from "@/models/ISOCountry";
import Gva from "@/models/activity/Gva";
import Strategy from "@/models/strategy/Strategy";
import GVARate from "@/models/strategy/GvaRate";
import ScopeRate from "@/models/strategy/ScopeRate";
import YearRange from "@/models/strategy/YearRange";
import ScenarioConfig from "@/models/ScenarioConfig";
import { range } from "lodash";
import GrowthProjection from "@/models/strategy/GrowthProjection";
import { GrowthType } from "@/models/strategy/GrowthType";

export const BASE_YEARS = [2017, 2018, 2019, 2020, 2021, 2022];
export const TARGET_YEARS = range(2030, 2051);
export const BASELINE = "Baseline";
export const BASE_STRATEGY_CURVE_NAME = "Baseline Pathway";
export const BASE_STRATEGY_CURVE_NAME2 = "Baseline Case";
export const BASE_STRATEGY_CURVE_NAME3 = "Strategy Pathway";
export const LOCAL_STORAGE_VERSION_KEY = "se_app_version";

// -----------------
// STARTING VALUES
export const STARTING_COMPANY_NAME = "Acme Inc.";
export const STARTING_COUNTRY = new ISOCountry("Germany", "DEU");
export const DEFAULT_ACTIVITY_NAME = "Business Area";
export const DEFAULT_STRATEGY_NAME = "Strategy";
export const STARTING_STRATEGY = new Strategy(
  1,
  BASELINE,
  1,
  [
    new YearRange(
      1,
      BASE_YEARS[BASE_YEARS.length - 1] + 1,
      TARGET_YEARS[TARGET_YEARS.length - 1]
    ),
  ],
  [new GVARate()],
  [new ScopeRate()],
  [new ScopeRate()],
  [new ScopeRate()],
  [
    new GrowthProjection(
      0,
      GrowthType.PERCENTAGE,
      0,
      0,
      0,
      0,
      BASE_YEARS[BASE_YEARS.length - 1],
      TARGET_YEARS[TARGET_YEARS.length - 1]
    ),
  ]
);
export const STARTING_ACTIVITY = new Activity(
  1,
  "Business Area 1",
  new Gva(1_000_000_000, 500_000_000),
  new ScopeEmissions(1_500_000, []),
  new ScopeEmissions(500_000, []),
  new ScopeEmissions(1_200_000, []),
  [STARTING_STRATEGY]
);

export const STARTING_SCENARIO = new ScenarioConfig(1, "Baseline", { 1: 1 });
