import axios, { AxiosError, AxiosInstance, AxiosResponse } from "axios";
import TokenStorage from "@/auth/services/TokenStorage";
import router from "@/router";
import { store } from "@/store/main";
import Config from "@/constants/Config";
import { successfulResponse } from "@/services/SuccesfulResponse";

const GET = "get";
const POST = "post";
const PUT = "put";
const DELETE = "delete";
type RequestMethod = typeof GET | typeof POST | typeof PUT | typeof DELETE;

// annotation for singleton
export default class AuthGateway {
  static AUTH_GATEWAY_BASE_URL = Config.AuthGatewayUrl;
  static AUTH_TENANT_ID = Config.AuthTenantId;
  static JWTRefreshPath = "/api/jwt/refresh";

  static createRequest(
    headers: Record<string, any> = {},
    baseUrl = ""
  ): AxiosInstance {
    return axios.create({
      baseURL: baseUrl === "" ? this.AUTH_GATEWAY_BASE_URL : baseUrl,
      withCredentials: false,
      headers: {
        "Content-Type": "application/json;  charset=utf-8",
        ...headers,
      },
    });
  }

  static _executeRequest(
    httpRequest: AxiosInstance,
    type: RequestMethod,
    url: string,
    body: Record<string, any>
  ): Promise<AxiosResponse> {
    const requests: Record<RequestMethod, () => Promise<AxiosResponse>> = {
      get: () => httpRequest.get(url),
      post: () => httpRequest.post(url, body),
      put: () => httpRequest.put(url, body),
      delete: () => httpRequest.delete(url),
    };

    return requests[type]()
      .then((response) => {
        if (response.status == 401 || response.status == 403) {
          store.commit("auth/setAuthState");
          router.push({ name: "Login" });
        }

        return response;
      })
      .catch((error: AxiosError) => {
        return error.response as AxiosResponse;
      });
  }

  static sendRequestWithAuth(
    baseUrl: string,
    type: RequestMethod,
    url: string,
    headers: Record<string, any> = {},
    data: Record<string, any> = {}
  ): Promise<AxiosResponse> {
    if (type === GET || type === POST) {
      headers["Content-Type"] = "application/json";
    }

    if (!TokenStorage.isTokenValid(TokenStorage.getToken())) {
      return AuthGateway.refreshToken()
        .then(() => {
          const httpRequest = this.createRequestWithHeader(headers, baseUrl);

          return AuthGateway._executeRequest(httpRequest, type, url, data);
        })
        .catch((error: AxiosResponse) => {
          store.commit("auth/setAuthState");
          router.push({ name: "Login" });

          return error;
        });
    }

    const httpRequest = this.createRequestWithHeader(headers, baseUrl);

    return AuthGateway._executeRequest(httpRequest, type, url, data);
  }

  private static createRequestWithHeader(
    headers: Record<string, any>,
    baseUrl: string
  ): AxiosInstance {
    const headersWithAuth: Record<string, any> = {
      ...headers,
      ...{ Authorization: "Bearer " + TokenStorage.getToken() },
    };

    return this.createRequest(headersWithAuth, baseUrl);
  }

  static refreshToken(): Promise<AxiosResponse> {
    const token = TokenStorage.getRefreshToken();

    if (!token) {
      const response: AxiosResponse = {
        status: 401,
        statusText: "Undefined or empty refresh token",
        data: [],
        headers: {},
        config: {},
      };

      return Promise.resolve(response);
    }

    return this.createRequest({
      "X-FusionAuth-TenantId": this.AUTH_TENANT_ID,
    })
      .post(this.JWTRefreshPath, TokenStorage.getRefreshHeader())
      .then((response: AxiosResponse) => {
        if (!successfulResponse(response)) {
          throw { response } as AxiosError;
        }

        const tokenJson: Record<string, any> = response.data;
        const { refreshToken, token } = tokenJson;
        TokenStorage.storeRefreshToken(refreshToken);
        TokenStorage.storeToken(token);
        store.commit("auth/setAuthState");

        return response;
      })
      .catch((error: AxiosError) => {
        TokenStorage.clear();
        store.commit("auth/setAuthState");
        router.push({ name: "Login" }, () => {
          store.commit(
            "routeStore/setErrorMessage",
            "Error: last login is to long ago. Please sign in again."
          );
        });

        return error.response as AxiosResponse;
      });
  }

  static logout(): void {
    TokenStorage.clear();
    store.commit("auth/setAuthState");

    if (router.currentRoute.name !== "Login") {
      router.push({ name: "Login" });
    }
  }
}
