import AuthGateway from "@/auth/services/AuthGateway";
import AuthState from "@/store/auth/AuthStoreState";
import AuthStoreState from "@/store/auth/AuthStoreState";
import TokenStorage from "@/auth/services/TokenStorage";

export const getAuthInitialState = (): AuthStoreState => {
  return {
    token: TokenStorage.getToken(),
    refreshToken: TokenStorage.getRefreshToken(),
    authenticated: TokenStorage.isAuthenticated(),
    email: "",
  };
};

export const authState: AuthStoreState = getAuthInitialState();

export const authStoreGetters = {};

export const authStoreMutations = {
  setAuthState(state: AuthState): void {
    state.token = TokenStorage.getToken();
    state.refreshToken = TokenStorage.getRefreshToken();
    state.authenticated = TokenStorage.isAuthenticated();
  },
  setEmail(state: AuthState, newValue: string): void {
    state.email = newValue;
  },
};

export const authStoreActions = {
  logout({ commit }: { commit: any }): void {
    AuthGateway.logout();
    commit("setAuthState");
    commit("clearState", null, { root: true });
  },
  getAuth({ state }: { state: AuthState }): any {
    const authUser = state.authenticated;
    const token = TokenStorage.getToken();
    const isTokenValid = TokenStorage.isTokenValid(token);

    return authUser && isTokenValid
      ? Promise.resolve(authUser)
      : AuthGateway.refreshToken();
  },
};

export const authStoreModule = {
  namespaced: true,
  state: authState,
  getters: authStoreGetters,
  mutations: authStoreMutations,
  actions: authStoreActions,
};
