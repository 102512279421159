export default {
  AuthGatewayUrl: process.env.VUE_APP_AUTH_BASE_URL ?? "",
  AuthApplicationId: process.env.VUE_APP_AUTH_APPLICATION_ID ?? "",
  AuthTenantId: process.env.VUE_APP_AUTH_TENANT_ID ?? "",
  AuthClientUrl: process.env.VUE_APP_AUTH_CLIENT_URL ?? "",
  ApiGatewayUrl: process.env.VUE_APP_API_GATEWAY_URL ?? "",
  ContactEmailAddress: "mailto:info@right-basedonscience.de",
  SubScopeFeatureToggle: false, // search for 'SubScopeFeatureToggle' to delete or update code for sub scope features
  TargetYearFeatureToggle: false,
};
