import {
  DEFAULT_ACTIVITY_NAME,
  DEFAULT_STRATEGY_NAME,
  STARTING_ACTIVITY,
  STARTING_STRATEGY,
} from "@/constants/Constants";
import {
  cloneDeep,
  difference,
  filter,
  intersectionBy,
  last,
  merge,
} from "lodash";
import Activity from "@/models/activity/Activity";
import Subscope from "@/models/Subscope";
import Strategy from "@/models/strategy/Strategy";
import RangeUpdate from "@/models/RangeUpdate";
import YearRange from "@/models/strategy/YearRange";
import HighchartCurve from "@/models/highcharts/HighchartCurve";
import { ExportableState, getInitialState, State } from "@/store/main/state";
import { MutationTree } from "vuex";
import Vue from "vue";
import ScenarioConfig from "@/models/ScenarioConfig";
import BaselineXDC from "@/models/xdc/BaselineXDC";
import HighChartEei from "@/models/highcharts/HighChartEei";

export const mutations: MutationTree<State> = {
  clearState(state) {
    Object.assign(state, getInitialState());
  },
  uploadState(state, importedState: ExportableState) {
    // Clear state
    Object.assign(state, getInitialState());

    // Put new values in
    merge(state, importedState);
  },
  curvesNeedReload(state, needReload) {
    Vue.prototype.$curvePainter.resetPalette();
    state.curvesReload = needReload;

    state.businessActivityCurves = [];
    state.selectedBusinessActivityCurves = [];
    state.ongoingChartsRequest = 0;

    state.highChartEei = new HighChartEei();
    state.selectedEmissionIntensityCurves = [];
    state.ongoingEmissionPathwaysRequests = 0;

    state.emissionIntensityXDCs = [];
    state.ongoingPathwaysXDCRequests = 0;
  },
  changeCompanyName(state, newValue) {
    state.companyName = newValue;
  },
  changeBaseYear(state, newValue) {
    state.baseYear = newValue;
    // remove all year ranges before the base year
    state.activities.forEach((activity) => {
      activity.strategies.forEach((strategy) => {
        strategy.yearRanges = strategy.yearRanges.filter(
          (yr) => yr.endYear > newValue
        );
      });
    });

    //update all year ranges
    state.activities.forEach((activity) => {
      activity.strategies.forEach((strategy) => {
        strategy.yearRanges[0].startYear = newValue + 1;
      });
    });
  },
  changeTargetYear(state, newValue) {
    state.targetYear = newValue;
    // remove all year ranges after the target year
    state.activities.forEach((activity) => {
      activity.strategies.forEach((strategy) => {
        strategy.yearRanges = strategy.yearRanges.filter(
          (yr) => yr.startYear <= newValue
        );
      });
    });
    //update all year ranges
    state.activities.forEach((activity) => {
      activity.strategies.forEach((strategy) => {
        strategy.yearRanges[strategy.yearRanges.length - 1].endYear = newValue;
      });
    });
  },
  changeCountry(state, newValue) {
    state.country = newValue;
  },
  changeNaceSector(state, newValue) {
    state.naceSector = newValue;
  },
  changeProvider(state, newValue) {
    state.provider = newValue;
  },
  changeSSP(state, newValue) {
    state.ssp = newValue;
  },
  changeExtension2100(state, newValue) {
    state.extension2100 = newValue;
  },
  addNewActivity(state) {
    if (state.activities.length === 0) {
      state.activities = [STARTING_ACTIVITY];
    } else {
      const activity = last(state.activities);

      if (activity) {
        const newActivity = configureNewActivity(state, activity);
        state.activities.push(newActivity);
      }
    }

    //Update Scenarios
    state.scenarios.forEach((scenario) => {
      state.activities.forEach((activity) => {
        scenario.addNewActivity(activity.id);
      });
    });
  },
  copyActivity(state, wishActivity: Activity) {
    const newActivity = configureNewActivity(state, wishActivity);
    state.activities.push(newActivity);
  },
  removeActivity(state, activityToDel) {
    state.activities = filter(
      state.activities,
      (act) => act.id !== activityToDel.id
    );

    //Update Scenarios
    state.scenarios.forEach((scenario) => {
      scenario.removeActivity(activityToDel.id);
    });
  },
  updateActivityXDC(state, payload) {
    const activityIdx = state.activities.findIndex(
      (a: Activity) => a.id === payload.activity.id
    );

    const newActivity = cloneDeep(payload.activity);
    newActivity.setNewBaselineXDC(payload.baselineXDC);

    state.activities.splice(activityIdx, 1, newActivity);
  },
  // [SubScopeFeatureToggle] remove if subscopes not re-enabled
  updateSubscope1List(state, newSubscopes: Array<Subscope>) {
    const removed = difference(state.selectedSubScopes1, newSubscopes);
    const added = difference(newSubscopes, state.selectedSubScopes1);
    state.selectedSubScopes1 = newSubscopes;

    const copyAct = cloneDeep(state.activities);
    copyAct.forEach((activity) => {
      activity.strategies.forEach((strategy) => {
        removed.forEach((rs: Subscope) => {
          activity.scope1.removeSubscope(rs);
          strategy.removeScope1Subscope(rs);
        });

        added.forEach((as: Subscope) => {
          activity.scope1.addSubscope(as);
          strategy.addScope1Subscope(as);
        });
      });
    });

    state.activities = copyAct;
  },
  // [SubScopeFeatureToggle] remove if subscopes not re-enabled
  updateSubscope2List(state, newSubscopes) {
    const removed = difference(state.selectedSubScopes2, newSubscopes);
    const added = difference(newSubscopes, state.selectedSubScopes2);
    state.selectedSubScopes2 = newSubscopes;

    const copyAct = cloneDeep(state.activities);
    copyAct.forEach((activity) => {
      activity.strategies.forEach((strategy) => {
        removed.forEach((rs: Subscope) => {
          activity.scope2.removeSubscope(rs);
          strategy.removeScope2Subscope(rs);
        });

        added.forEach((as: Subscope) => {
          activity.scope2.addSubscope(as);
          strategy.addScope2Subscope(as);
        });
      });
    });

    state.activities = copyAct;
  },
  // [SubScopeFeatureToggle] remove if subscopes not re-enabled
  updateSubscope3List(state, newSubscopes) {
    const removed = difference(state.selectedSubScopes3, newSubscopes);
    const added = difference(newSubscopes, state.selectedSubScopes3);
    state.selectedSubScopes3 = newSubscopes;

    const copyAct = cloneDeep(state.activities);
    copyAct.forEach((activity) => {
      activity.strategies.forEach((strategy) => {
        removed.forEach((rs: Subscope) => {
          activity.scope3.removeSubscope(rs);
          strategy.removeScope3Subscope(rs);
        });

        added.forEach((as: Subscope) => {
          activity.scope3.addSubscope(as);
          strategy.addScope3Subscope(as);
        });
      });
    });

    state.activities = copyAct;
  },
  addNewStrategy(state, payload) {
    const activity = state.activities.find((a) => a.id === payload.activityId);

    if (activity) {
      createAndStoreNewStrategy(
        activity,
        undefined,
        true,
        payload.baseYearRange
      );
    }
  },
  copyStrategyIntoActivity(state, strategy: Strategy) {
    const activity = state.activities.find(
      (a: Activity) => a.id === strategy.parentActivityId
    );

    if (activity) {
      createAndStoreNewStrategy(activity, strategy);
    }
  },
  copyStrategyToAll(state, strategy: Strategy) {
    const otherActivities = state.activities.filter(
      (a: Activity) => a.id !== strategy.parentActivityId
    );

    otherActivities.forEach((activity: Activity) => {
      createAndStoreNewStrategy(activity, strategy, false);
    });
  },
  removeStrategy(state, strategy: Strategy) {
    const activity = state.activities.find(
      (a: Activity) => a.id === strategy.parentActivityId
    );

    if (activity) {
      const strategyIdx = activity.strategies.findIndex(
        (s: Strategy) => s.id === strategy.id
      );

      activity.strategies.splice(strategyIdx, 1);

      state.scenarios.forEach((s) => {
        s.upsertStrategyIdOf(activity.id, 1);
      });
    }
  },
  updateRanges(state, update: RangeUpdate) {
    const activity = state.activities.find(
      (a: Activity) => a.id === update.activityId
    );
    const strategy = activity
      ? activity.strategies.find((s: Strategy) => s.id === update.strategyId)
      : undefined;

    if (activity && strategy) {
      const oldRanges = strategy.yearRanges;
      const newRanges = update.updatedRanges;
      const newStrategy = new Strategy(
        strategy.id,
        strategy.name,
        strategy.parentActivityId,
        [],
        [],
        [],
        [],
        [],
        []
      );

      const stillPresentIds = intersectionBy(
        oldRanges,
        newRanges,
        (r) => r.id
      ).map((range) => range.id);

      // start with new Ranges
      // if ID existed before copy the oldRanges[idx] into new list
      // else copy newRange and set newValues
      newRanges.forEach((range: YearRange) => {
        if (stillPresentIds.includes(range.id)) {
          const oldIdx = oldRanges.findIndex((r) => r.id === range.id);

          newStrategy.addExistingRange(strategy, oldIdx, range);
        } else {
          newStrategy.addNewRange(range);
        }
      });

      newStrategy.recalculateInitialValues(activity);

      const strategyIdx = activity.strategies.findIndex(
        (s: Strategy) => s.id === update.strategyId
      );
      activity.strategies.splice(strategyIdx, 1, newStrategy);
    }
  },
  selectChartCurve(state, curve: HighchartCurve) {
    const index = state.selectedBusinessActivityCurves.findIndex(
      (c) => c.id === curve.id
    );

    if (index === -1) {
      const length = state.selectedBusinessActivityCurves.length;
      state.selectedBusinessActivityCurves.splice(length, 0, curve);
    }
  },
  deselectChartCurve(state, curve: HighchartCurve) {
    const index = state.selectedBusinessActivityCurves.findIndex(
      (c) => c.id === curve.id
    );

    if (index > -1) {
      state.selectedBusinessActivityCurves.splice(index, 1);
    }
  },
  addRequestForActivityTrajectories(state) {
    state.ongoingChartsRequest++;
  },
  receivedRequestForActivityTrajectories(state) {
    if (state.ongoingChartsRequest > 0) {
      state.ongoingChartsRequest--;
    }
  },
  selectEeiCurve(state, curve: HighchartCurve) {
    const index = state.selectedEmissionIntensityCurves.findIndex(
      (c) => c.id === curve.id
    );

    if (index === -1) {
      const length = state.selectedEmissionIntensityCurves.length;
      state.selectedEmissionIntensityCurves.splice(length, 0, curve);
    }
  },
  deselectEeiCurve(state, curve: HighchartCurve) {
    const index = state.selectedEmissionIntensityCurves.findIndex(
      (c) => c.id === curve.id
    );

    if (index > -1) {
      state.selectedEmissionIntensityCurves.splice(index, 1);
    }
  },
  addRequestForEmissionPathways(state) {
    state.ongoingEmissionPathwaysRequests++;
  },
  receivedRequestForEmissionPathways(state) {
    if (state.ongoingEmissionPathwaysRequests > 0) {
      state.ongoingEmissionPathwaysRequests--;
    }
  },
  addRequestForPathwaysXDC(state) {
    state.ongoingPathwaysXDCRequests++;
  },
  receivedRequestForPathwaysXDC(state) {
    if (state.ongoingPathwaysXDCRequests > 0) {
      state.ongoingPathwaysXDCRequests--;
    }
  },
  createNewScenario(state) {
    const map: Record<number, number> = {};
    state.activities.forEach((activity) => {
      map[activity.id] = 1;
    });

    const lastItem = last(state.scenarios);

    const differentialId = lastItem ? lastItem.id + 1 : 1;
    state.scenarios.push(
      new ScenarioConfig(differentialId, `Scenario Name ${differentialId}`, map)
    );
  },
  removeScenario(state, scenario: ScenarioConfig) {
    const delIdx = state.scenarios.findIndex((s) => s.id === scenario.id);

    state.scenarios.splice(delIdx, 1);
  },
  copyScenario(state, scenario: ScenarioConfig) {
    const lastItem = last(state.scenarios);

    const differentialId = lastItem ? lastItem.id + 1 : 1;
    const lastIdx = state.scenarios.length;

    const newScenario = cloneDeep(scenario);
    newScenario.id = differentialId;
    newScenario.name = `${newScenario.name} (copy)`;

    state.scenarios.splice(lastIdx, 0, newScenario);
  },
};

function createAndStoreNewStrategy(
  activity: Activity,
  strategy: Strategy | undefined,
  newName = true,
  baseYearRange: YearRange | undefined = undefined
): void {
  const lastStrategy = last(activity.strategies);
  const lastID = lastStrategy ? lastStrategy.id : 1;
  const isNewStrategy = !strategy;
  const newStrategy = strategy ? cloneDeep(strategy) : cloneDeep(lastStrategy);

  if (newStrategy) {
    newStrategy.id = lastID + 1;
    newStrategy.parentActivityId = activity.id;

    // remove eventual growth projections from previous strategies
    if (isNewStrategy && baseYearRange) {
      newStrategy.growthProjections = [newStrategy.growthProjections[0]];
      newStrategy.yearRanges = [baseYearRange];
    }

    newStrategy.name = newName
      ? `${DEFAULT_STRATEGY_NAME} #${newStrategy.id}`
      : newStrategy.name;
    newStrategy.recalculateInitialValues(activity);
    newStrategy.baselineXDC = new BaselineXDC();
    activity.strategies.push(newStrategy);
  }
}

function configureNewActivity(state: State, activity: Activity): Activity {
  const lastActivity = last(state.activities);
  const lastID = lastActivity ? lastActivity.id : 1;
  const newActivity = cloneDeep(activity);
  newActivity.id = lastID + 1;
  newActivity.name = `${DEFAULT_ACTIVITY_NAME} ${newActivity.id}`;
  newActivity.strategies = [STARTING_STRATEGY];

  return newActivity;
}
