
import { Component, Vue } from "vue-property-decorator";
import ScenarioConfig from "@/models/ScenarioConfig";
import ActivityStrategyComboData from "@/pages/data/components/ActivityStrategyComboData.vue";
import ActivityStrategyData from "@/models/data-tab/ActivityStrategyData";
import ScenarioData from "@/models/data-tab/ScenarioData";
import {
  BASE_STRATEGY_CURVE_NAME,
  BASE_STRATEGY_CURVE_NAME2,
  BASE_STRATEGY_CURVE_NAME3,
} from "@/constants/Constants";
import BusinessActivityCurvesGroup from "@/models/trajectory/BusinessActivityCurvesGroup";
import DataExportFab from "@/pages/data/components/DataExportFab.vue";
import Assumptions from "@/pages/strategy/components/Assumptions.vue";
import { MISSING_INFORMATION } from "@/constants/ErrorMessages";

@Component({
  components: { DataExportFab, ActivityStrategyComboData, Assumptions },
})
export default class DataPage extends Vue {
  missingInformation = MISSING_INFORMATION;
  selectedScenario = null;

  get scenarios(): ScenarioConfig[] {
    return this.$store.state.scenarios;
  }

  get baCurves(): BusinessActivityCurvesGroup[] {
    return this.$store.state.businessActivityCurves;
  }

  activityStrategyData(scenario: ScenarioConfig): ActivityStrategyData[] {
    const scenarioData = new ScenarioData(scenario.id);

    for (const key in scenario.selectedActivityToStrategyMap) {
      const activityId = Number(key);
      const strategyId = scenario.selectedActivityToStrategyMap[activityId];

      const bac = this.baCurves.find(
        (bac) =>
          bac.parentActivityId === activityId &&
          bac.parentStrategyId === strategyId
      );

      if (bac) {
        const relevantCurve = bac.curves.find(
          (c) =>
            c.name === BASE_STRATEGY_CURVE_NAME ||
            c.name === BASE_STRATEGY_CURVE_NAME2 ||
            c.name === BASE_STRATEGY_CURVE_NAME3
        );

        if (relevantCurve) {
          const data = new ActivityStrategyData(
            activityId,
            strategyId,
            relevantCurve
          );

          scenarioData.addActivityStrategyData(data);
        }
      }
    }

    return scenarioData.activityStrategyDataList;
  }

  get isLoadingCurves(): boolean {
    return this.curveFetchCount !== 0;
  }

  get curveFetchCount(): number {
    return this.$store.state.ongoingChartsRequest;
  }

  get isNaceFilled(): boolean {
    return this.$store.state.naceSector.code === "";
  }

  created(): void {
    if (this.$store.state.curvesReload && !this.isNaceFilled) {
      this.$store.commit("curvesNeedReload", false);
      this.$store.dispatch("makeCompanyTrajectoriesRequest");
      this.$store.dispatch("makeIntensityPathwaysRequest");
    }
  }
}
